import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function KarmaCategoryList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "name", sortable: false, label: "Name" },
        // { key: "description", sortable: false, label: "description" },
        { key: "parent", sortable: false, label: "parent" },
        { key: "status", sortable: false, label: "status" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalKarmaCategoryList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const karmaCategoriesMetaData = ref([]);
    const karmaCategoryStatusOption = ref([]);
    const categoryFilter = ref(null);
    const parentCategoryOption = ref([]);
    const employeeFilter = ref(null);
    const employeeOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalKarmaCategoryList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
            categoryFilter,
            employeeFilter
        ],
        () => {
            refetchData();
        }
    );

    const fetchKarmaCategoryList = (ctx, callback) => {
        store
            .dispatch("app-karma-categories/fetchKarmaCategories", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                categoryFilter: categoryFilter.value,
                employeeFilter: employeeFilter.value,
            })
            .then(response => {
                const karma_category = response.data.data;
                const { total } = response.data.meta;
                karmaCategoryStatusOption.value = response.data.status;
                parentCategoryOption.value = response.data.parent_category_option;
                employeeOption.value = response.data.employee_option;

                callback(karma_category);
                totalKarmaCategoryList.value = total;
                karmaCategoriesMetaData.value = karma_category;
            })
            .catch(error => {
                console.error(error);
                // toast({
                //     component: ToastificationContent,
                //     props: {
                //         title: "Error fetching karma-categories",
                //         icon: "AlertTriangleIcon",
                //         variant: "danger"
                //     }
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error fetching karma-categories',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  }, {
                    timeout: 2000, 
                    position: 'bottom-center',
                    toastClassName:"error-info ",
                    hideProgressBar : true,
                  });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchKarmaCategoryList,
        tableColumns,
        perPage,
        currentPage,
        totalKarmaCategoryList,
        karmaCategoriesMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        karmaCategoryStatusOption,
        statusFilter,
        categoryFilter,
        parentCategoryOption,
        employeeFilter,
        employeeOption,
    };
}
